import React from "react";

import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Link } from "~components/Link";

import { Box } from "../../Box/index";
import * as styles from "../styles.css";
import { CardDescription } from "../subComponents/CardDescription";
import { CardImage } from "../subComponents/CardImage";
import { CardLogo } from "../subComponents/CardLogo";
import { CardTitle } from "../subComponents/CardTitle";

import type { VariantCardProps } from "../types";
import type { LinkProps } from "~components/Link";

export function PeopleCard({
  userClassName,
  atomProps,
  otherProps,
  image,
  title,
  description,
  logo,
  link,
}: VariantCardProps) {
  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({
      ...atomProps,
    }),
    styles.getCardStyles({
      appearance: "people",
      padding: !image,
    })
  );

  const linkProps: LinkProps = {
    fallbackElement: "div",
    link,
    className: cardWrapperClassNames,
    ...otherProps,
  };

  return (
    <Link {...linkProps}>
      {image && (
        <Box className={styles.imagePeopleWrapper}>
          <CardImage
            image={image}
            boxClassName={styles.imageOuter}
            position="relative"
            height="100%"
            imageClassName={styles.imageInner}
            objectFit="cover"
          />
        </Box>
      )}
      <Box
        marginY="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {title && <CardTitle title={title} />}
        {description && <CardDescription text={description} />}
        {logo && <CardLogo logo={logo!} />}
      </Box>
    </Link>
  );
}
