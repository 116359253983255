import React from "react";

import * as styles from "../styles.css";

export interface CardPrefixProps {
  prefix?: string;
}

export function CardPrefix({ prefix }: CardPrefixProps) {
  return <p className={styles.prefix}>{prefix}</p>;
}
