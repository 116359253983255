import React from "react";

import { Box } from "~components/Box";
import { SvgLogo } from "~components/SvgLogo";

import * as styles from "../styles.css";

import type { SvgLogoProps } from "~components/SvgLogo";

export interface CardLogoProps {
  logo: SvgLogoProps["svgLogo"];
}

export function CardLogo({ logo }: CardLogoProps) {
  return (
    <Box className={styles.logoOuter}>
      <SvgLogo
        svgLogo={logo}
        color="text_highContrast"
        className={styles.logo}
      />
    </Box>
  );
}
