import React from "react";

import { faCalendar } from "@fortawesome/sharp-regular-svg-icons/faCalendar";

import { formatDate } from "~utils/__deprecated__/date_utils/format_date";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

export interface CardDateProps {
  date: string;
}

export function CardDate({ date }: CardDateProps) {
  return (
    <Box
      color="text_lowContrast"
      gap="spacing1"
      display="flex"
      flexShrink="0"
      whiteSpace="nowrap"
      alignItems="center"
      textAppearance="body_sm"
    >
      <IconFontAwesome icon={faCalendar} /> {formatDate(date)}
    </Box>
  );
}
