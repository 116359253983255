import React from "react";

import { faPlayCircle } from "@fortawesome/sharp-regular-svg-icons/faPlayCircle";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { EmbedWistia } from "~components/EmbedWistia";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Link } from "~components/Link";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { playButtonStyle } from "~components/VideoPlayButton/styles.css";

import * as styles from "../styles.css";
import { CardCta } from "../subComponents/CardCtaText";
import { CardDate } from "../subComponents/CardDate";
import { CardDescription } from "../subComponents/CardDescription";
import { CardIcon } from "../subComponents/CardIcon";
import { CardImage } from "../subComponents/CardImage";
import { CardLocation } from "../subComponents/CardLocation";
import { CardLogo } from "../subComponents/CardLogo";
import { CardLogosList } from "../subComponents/CardLogosList";
import { CardPrefix } from "../subComponents/CardPrefix";
import { CardTitle } from "../subComponents/CardTitle";
import { CardTopic } from "../subComponents/CardTopic";

import type { VariantCardProps } from "../types";
import type { LinkProps } from "~components/Link";

export function GenericCard({
  userClassName,
  atomProps,
  otherProps,
  appearance,
  image,
  title,
  description,
  logo,
  date,
  location,
  brandIcon,
  prefix,
  logos,
  ctaText,
  ctaIcon,
  link,
  primaryTopic,
  video,
}: VariantCardProps) {
  const isContactUs = appearance === "contactUs";
  const hasMedia = image || video;

  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({
      ...atomProps,
    }),
    styles.getCardStyles({
      appearance,
      padding: isContactUs ? false : !hasMedia,
    })
  );

  const linkProps: LinkProps = {
    fallbackElement: "div",
    link,
    className: cardWrapperClassNames,
    ...otherProps,
  };

  const [wistiaVideo] = video || [];

  return (
    <Link {...linkProps}>
      {/* Image */}
      {image && (
        <CardImage
          boxClassName={styles.imageOuter}
          position="relative"
          height="100%"
          width="auto"
          image={image}
          imageClassName={styles.imageInner}
          objectFit="cover"
        >
          {appearance === "video" && (
            <IconFontAwesome icon={faPlayCircle} className={playButtonStyle} />
          )}
        </CardImage>
      )}

      {/* Video */}
      {wistiaVideo && (
        <Box width="100%" objectFit="cover">
          <StoryblokEditable {...wistiaVideo}>
            <EmbedWistia {...wistiaVideo} />
          </StoryblokEditable>
        </Box>
      )}

      {/* Brand icon */}
      {brandIcon && !image && <CardIcon brandIcon={brandIcon} />}

      {
        <div className={styles.cardInner}>
          <div className={styles.cardHeader}>
            {/* Info (topic, date, location, logo) */}
            <Box display="flex" gap="spacing1" marginBottom="spacing1">
              {(primaryTopic || date || location) && (
                <Box
                  display="flex"
                  flexDirection={logo ? "column" : "row"}
                  justifyContent={logo ? "center" : "start"}
                  width={logo ? "auto" : "100%"}
                  rowGap={logo ? "spacing1.5" : "none"}
                >
                  {primaryTopic && <CardTopic topic={primaryTopic} />}
                  {date && <CardDate date={date} />}
                  {location && <CardLocation location={location} />}
                </Box>
              )}
              {logo && <CardLogo logo={logo} />}
            </Box>

            {/* Prefix & title */}
            {prefix && <CardPrefix {...{ prefix }} />}

            {/* Title */}
            {title && <CardTitle title={title!} />}
          </div>

          {/* Description */}
          {description && <CardDescription text={description} />}

          {/* Logos */}
          {logos && <CardLogosList {...{ logos }} />}

          {/* Cta text */}
          {ctaText && <CardCta {...{ ctaIcon, ctaText }} />}
        </div>
      }
    </Link>
  );
}
