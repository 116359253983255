import React from "react";

import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import { faMessages } from "@fortawesome/sharp-regular-svg-icons/faMessages";
import { faSquareTerminal } from "@fortawesome/sharp-regular-svg-icons/faSquareTerminal";

import { IconFontAwesome } from "~components/IconFontAwesome";

import * as styles from "../styles.css";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";

const ICON_MAP: Record<string, IconProp> = {
  faArrowRightLong,
  faSquareTerminal,
  faMessages,
} as const;

type CardCtaIcon = keyof typeof ICON_MAP;

export interface CardCtaProps {
  ctaText?: string | null;
  ctaIcon?: CardCtaIcon | null;
}

export function CardCta({ ctaIcon, ctaText }: CardCtaProps) {
  const dynamicIcon: IconProp = ICON_MAP[
    ctaIcon || "faArrowRightLong"
  ] as IconProp;

  return (
    <div className={styles.fakeButtonLink}>
      <IconFontAwesome icon={dynamicIcon} color="ui_primary_base" />
      {ctaText}
    </div>
  );
}
