import React from "react";

import { formatDate } from "~utils/__deprecated__/date_utils/format_date";

import { Avatar } from "~components/Avatar";
import { Box } from "~components/Box";

import type { AuthorEntry } from "~types/storyblok.types";

export interface ContentAuthorDateProps {
  author: AuthorEntry | undefined;
  date: string | undefined;
}

/**
 * Given fields from an "authorContent" Storyblok entry, and an ISO date
 * string, renders and avatar, name, job title and formatted date.
 */
export function ContentAuthorDate({ author, date }: ContentAuthorDateProps) {
  const authorContent = author?.content;

  if (!authorContent && !date) {
    return null;
  }

  const { avatar, jobTitle, name } = authorContent || {};

  const nameString = [name, jobTitle].join(", ");

  return (
    <Box
      id="content-author-date"
      display="flex"
      gap="spacing1.5"
      alignItems="center"
    >
      {avatar && <Avatar image={avatar} />}
      <Box fontWeight="semibold" color="text_lowContrast" fontSize="body_md">
        {author && <Box>{nameString}</Box>}
        {date && <Box>{formatDate(date)}</Box>}
      </Box>
    </Box>
  );
}
