import React from "react";

import * as styles from "./styles.css";
import { getWistiaWrapperId } from "./wistiaUtils";

export interface VideoWistiaProps {
  videoId: string;
}

export function VideoWistia({ videoId }: VideoWistiaProps) {
  const wrapperId = getWistiaWrapperId(videoId);
  const wistiaSwatchId = `wistia_swatch_${videoId}`;

  const handleLoad = () => {
    const wistiaSwatch = document.getElementById(wistiaSwatchId);
    if (!wistiaSwatch) return;
    wistiaSwatch.style.opacity = "1";
  };

  React.useEffect(() => {
    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    const wrapper = document.getElementById(wrapperId);
    wrapper?.appendChild(script1);
    wrapper?.appendChild(script2);
  }, [videoId, wrapperId]);

  return (
    <div className={styles.customVideoWrapper} id={wrapperId}>
      <div
        className={`wistia_responsive_padding ${styles.wistiaResponsivePadding}`}
      >
        <div
          className={`wistia_responsive_wrapper ${styles.wistiaResponsiveWrapper}`}
        >
          <div
            className={`wistia_embed wistia_async_${videoId} seo=false videoFoam=true ${styles.wistiaEmbed}`}
          >
            <div
              id={wistiaSwatchId}
              className={`wistia_swatch ${styles.wistiaSwatch}`}
            >
              <img
                src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
                className={`${styles.wistiaImg}`}
                alt=""
                aria-hidden="true"
                onLoad={handleLoad}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
