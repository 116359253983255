import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import {
  Content as RadixCollapsibleContent,
  Root as RadixCollapsibleRoot,
  Trigger as RadixCollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import * as styles from "./styles.css";

import type { CollapsibleProps as RadixCollapsibleProps } from "@radix-ui/react-collapsible";
import type { ReactNode } from "react";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface CollapsibleProps
  extends Omit<RadixCollapsibleProps, "color">,
    GetSprinklesArgs {
  children: ReactNode;
  className?: string;
  triggerContentNode: ReactNode;
}
export function Collapsible({
  triggerContentNode,
  children,
  className: userClassName,
  ...rest
}: CollapsibleProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <RadixCollapsibleRoot
      className={clsx(userClassName, getSprinkles({ ...atomProps }))}
      id="collapsible"
      {...otherProps}
    >
      <RadixCollapsibleTrigger
        name="collapsible-trigger"
        className={styles.trigger}
        asChild
      >
        {triggerContentNode}
      </RadixCollapsibleTrigger>

      <RadixCollapsibleContent className={styles.content}>
        {children}
      </RadixCollapsibleContent>
    </RadixCollapsibleRoot>
  );
}
