import React from "react";

import { faArrowRight } from "@fortawesome/sharp-regular-svg-icons/faArrowRight";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { FormHubspotDialog } from "~components/FormHubspotDialog";

import type { ConversionComponent } from "../../utils/analytics/dispatchConversionEvent/index";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { BoxProps } from "~components/Box";
import type { ButtonProps } from "~components/Button";
import type { FormHubspotDialogProps } from "~components/FormHubspotDialog";
import type { ConversionEvent } from "~config/CONVERSION_TYPES.config";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { StoryblokBlok, StoryblokFieldLink } from "~types/storyblok.types";

export interface CtaCardProps extends Omit<BoxProps, "background"> {
  buttonText?: string;
  link?: StoryblokFieldLink;
  text?: string;
  title?: string;
  background?: VariantBackgroundColorEnum;
  hubspotForm?: StoryblokBlok[];
  conversionType: ConversionEvent;
}

export interface LinkTransformerProps {
  dialogTitle: string;
  buttonTitle: string;
  conversionType: ConversionEvent;
  conversionComponent: ConversionComponent;
  appearance?: ButtonProps["appearance"];
  iconRight?: IconProp;
}

function checkSlug(link: StoryblokFieldLink, slugToCheck: string) {
  return link?.story?.slug === slugToCheck;
}

function transformLinkIntoDialog({
  dialogTitle,
  buttonTitle,
  conversionType,
  conversionComponent,
  appearance = "primary",
  iconRight,
}: LinkTransformerProps) {
  return (
    <FormHubspotDialog
      {...{ dialogTitle, buttonTitle, conversionType }}
      buttonProps={{ appearance, iconRight: iconRight ?? faArrowRight }}
      conversionType={conversionType}
      conversionComponent={conversionComponent}
    />
  );
}

function getButton(
  link: StoryblokFieldLink,
  buttonText: string,
  conversionType: ConversionEvent,
  conversionComponent: ConversionComponent
) {
  const isLinkToContact = checkSlug(link, "contact");

  if (isLinkToContact) {
    return transformLinkIntoDialog({
      dialogTitle: buttonText,
      buttonTitle: buttonText,
      conversionType,
      conversionComponent,
    });
  }

  return (
    <ButtonLink link={link} name={buttonText} width="max-content">
      {buttonText}
    </ButtonLink>
  );
}

/** Renders a cta card with text and a button that navigates the user to a supplied link. */
export function CtaCard({
  buttonText,
  link,
  text,
  background = "background_charcoal",
  title,
  hubspotForm,
  conversionType = "contact_sales",

  ...rest
}: CtaCardProps) {
  const conversionComponent = "CtaCard";
  const [hubspotFormDialog] = (hubspotForm as [FormHubspotDialogProps]) ?? [];

  return (
    <Box
      borderRadius="sm"
      border="default"
      width="100%"
      paddingY="spacing6"
      paddingX="spacing3"
      id="cta"
      color="text_highContrast"
      className={variantBackgroundColor[background]}
      {...rest}
    >
      <Box textAppearance="h6" maxWidth="gridSpan8" marginBottom="spacing2">
        {title}
      </Box>
      <Box
        textAppearance="body_md"
        maxWidth="gridSpan8"
        marginBottom="spacing2"
      >
        {text}
      </Box>

      {link &&
        buttonText &&
        getButton(link, buttonText, conversionType, conversionComponent)}

      {hubspotFormDialog && (
        <FormHubspotDialog
          {...hubspotFormDialog}
          buttonProps={{
            appearance: "primary",
            iconRight: faArrowRight,
          }}
          conversionComponent={conversionComponent}
        />
      )}
    </Box>
  );
}
