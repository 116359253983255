import React from "react";

import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Button } from "~components/Button";
import { Link } from "~components/Link";

import * as styles from "../styles.css";
import { CardCta } from "../subComponents/CardCtaText";
import { CardDescription } from "../subComponents/CardDescription";
import { CardIcon } from "../subComponents/CardIcon";
import { CardImage } from "../subComponents/CardImage";
import { CardInfo } from "../subComponents/CardInfo";
import { CardLogo } from "../subComponents/CardLogo";
import { CardLogosList } from "../subComponents/CardLogosList";
import { CardPrefix } from "../subComponents/CardPrefix";
import { CardTitle } from "../subComponents/CardTitle";

import type { VariantCardProps } from "../types";
import type { ButtonProps } from "~components/Button";
import type { LinkProps } from "~components/Link";

export function FeaturedCard({
  userClassName,
  atomProps,
  otherProps,
  appearance,
  logo,
  date,
  location,
  brandIcon,
  prefix,
  title,
  description,
  logos,
  ctaText,
  ctaIcon,
  image,
  featuredCta,
  featuredContentType,
  link,
}: VariantCardProps) {
  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({
      ...atomProps,
    }),
    styles.getCardStyles({
      appearance,
      featuredContentType,
    })
  );

  const linkProps: LinkProps = {
    fallbackElement: "div",
    link,
    className: cardWrapperClassNames,
    ...otherProps,
  };

  return (
    <Link {...linkProps}>
      <div className={clsx(styles.cardInner, styles.cardInnerFeatured)}>
        {logo && <CardLogo logo={logo} />}
        <div className={styles.cardHeader}>
          {/* Brand icon - Featured */}
          {brandIcon && <CardIcon brandIcon={brandIcon} />}
          {/* Date & location */}
          {(date || location) && <CardInfo date={date} location={location} />}

          {/* Prefix & title */}
          {prefix && <CardPrefix {...{ prefix }} />}

          {/* Title */}
          {title && <CardTitle title={title} />}
        </div>

        {/* Description */}
        {description && <CardDescription text={description} />}

        {/* Logos */}
        {logos && <CardLogosList {...{ logos }} />}

        {/* Cta text */}
        {ctaText && <CardCta {...{ ctaIcon, ctaText }} />}

        {/* Read more Cta */}
        {featuredCta && (
          <Button
            background="background_white"
            className={styles.featuredCtaStyle}
            {...(featuredCta as ButtonProps)}
          />
        )}
      </div>

      {/* Image - Featured */}
      {image && (
        <CardImage
          boxClassName={styles.imageOuterFeatured}
          height="100%"
          image={image}
          imageWrapperClassName={styles.imageInnerFeatured}
          imageClassName={styles.imageFeatured}
          objectFit="contain"
        />
      )}
    </Link>
  );
}
