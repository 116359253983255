import React, { useMemo } from "react";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";
import { transformStoryblokEntryNodeToCard } from "~utils/transformers/transformStoryblokEntryNodeToCard";

import { Card } from "../Card";

import type { CardProps } from "../Card";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export interface ContentListItemsProps {
  cardAppearance?: CardProps["appearance"];
  cardProps?: CardProps;
  items?: Array<SbRelationsGraphQlNode>;
}

/** Renders a list of content as `card` components */
export function ContentListItems({
  items,
  cardAppearance = "blogPost",
  cardProps,
}: ContentListItemsProps) {
  const cards = useMemo(() => {
    return items?.map(transformStoryblokEntryNodeToCard);
  }, [items]);

  if (!cards || !checkHasLength(items)) {
    return null;
  }

  return (
    <>
      {cards.map((card) => {
        return (
          <Card
            {...card}
            {...cardProps}
            key={card._uid}
            appearance={cardAppearance}
          />
        );
      })}
    </>
  );
}
