import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/sharp-regular-svg-icons/faCalendarDay";
import { faLocationDot } from "@fortawesome/sharp-regular-svg-icons/faLocationDot";

import { Box } from "~components/Box";

import { dateLocationIconStyle, dateLocationItemStyle } from "./styles.css";

import type { StoryblokBlok } from "../../types/storyblok.types";

export interface EventDateLocationProps extends StoryblokBlok {
  date?: string;
  location?: string;
}

interface DateLocationItemProps {
  type: "date" | "location";
  content?: string;
}

function DateLocationItem({ type, content }: DateLocationItemProps) {
  const icon = type === "date" ? faCalendarDay : faLocationDot;

  return (
    <Box className={dateLocationItemStyle}>
      <FontAwesomeIcon icon={icon} className={dateLocationIconStyle} />
      {content}
    </Box>
  );
}

export function EventDateLocation({ date, location }: EventDateLocationProps) {
  return (
    <Box>
      {date && <DateLocationItem type="date" content={date} />}
      {location && <DateLocationItem type="location" content={location} />}
    </Box>
  );
}
