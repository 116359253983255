import React from "react";

import { PROXIMA } from "~styles/typography.css";

import { ChannelWistia } from "./ChannelWistia";
import { VideoWistia } from "./VideoWistia";

import type { ComponentMap } from "~types/global.types";

const enum EmbedTypes {
  CHANNEL = "channel",
  VIDEO = "video",
}

export interface EmbedWistiaProps {
  embedType: EmbedTypes;
  mediaId: string;
}

export function EmbedWistia(
  this: ComponentMap,
  { embedType, mediaId }: EmbedWistiaProps
) {
  if (!mediaId) return null;

  switch (embedType) {
    case EmbedTypes.CHANNEL:
      return <ChannelWistia channelId={mediaId} customFont={PROXIMA} />;
    case EmbedTypes.VIDEO:
      return <VideoWistia videoId={mediaId} />;
    default:
      return null;
  }
}
