import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";

import { recipeMaxWidth } from "~styles/common/recipeMaxWidth.css";

import { Box } from "~components/Box";
import { Grid } from "~components/Grid";

import { THEME_CONFIG } from "~config/THEME_CONFIG.config";

import * as styles from "./styles.css";

const GRID_TEMPLATE_COLUMNS = {
  desktop: THEME_CONFIG.grid.gridColumns.desktop,
  tablet: THEME_CONFIG.grid.gridColumns.tablet,
  mobile: THEME_CONFIG.grid.gridColumns.mobile,
};
export function DevLayoutGrid() {
  const [shouldShowGrid, setShouldShowGrid] = useState(false);

  const handler = useCallback((e: KeyboardEvent) => {
    if (e.isComposing || e.key === "l") {
      setShouldShowGrid((current) => {
        return !current;
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", handler, false);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handler, false);
      }
    };
  }, [handler]);

  if (!shouldShowGrid) return null;

  return (
    <Box className={styles.devLayoutGridOuter} position="relative">
      <Grid
        height="100vh"
        width="100vw"
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
        className={clsx(recipeMaxWidth({ maxWidth: "gridSpan12" }))}
      >
        {Array.from({ length: 12 }, (_, index) => {
          return (
            <Box
              id={`dev-layout-grid-${index}`}
              key={`dev-layout-grid-${index}`}
              className={clsx(styles.gridCell, {
                [styles.mobileCell]:
                  index < THEME_CONFIG.grid.gridColumns.mobile,
                [styles.tabletCell]:
                  index < THEME_CONFIG.grid.gridColumns.tablet,
                [styles.desktopCell]:
                  index >= THEME_CONFIG.grid.gridColumns.tablet,
              })}
            />
          );
        })}
      </Grid>
    </Box>
  );
}
