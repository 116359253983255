import React, { useCallback, useEffect, useState } from "react";

import { Script, ScriptStrategy, navigate } from "gatsby";

import { Box } from "~components/Box";
import { Loader } from "~components/Loader";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { WindowWithGrnhseGlobal } from "~types/greenhouse.types";

const IFRAME_ID = "grnhse_app";

export interface FormGreenhouseJobApplicationProps extends GetSprinklesArgs {
  publicJobId: number;
}

const handleSubmission = (event: MessageEvent) => {
  if (
    typeof window !== "undefined" &&
    event.origin ===
      (window as WindowWithGrnhseGlobal)?.Grnhse?.Settings?.targetDomain &&
    event.data === "greenhouse.confirmation"
  ) {
    // ToDo: [Tech debt] [Careers hub] Avoid hard coding applied link
    navigate("/jobs/applied");
  }
};

export function FormGreenhouseJobApplication({
  publicJobId,
}: FormGreenhouseJobApplicationProps) {
  const [isLoaded, setLoaded] = useState(false);

  const onLoadCallback = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const greenhouse = (window as WindowWithGrnhseGlobal).Grnhse;
      const iframe = greenhouse.Iframe;

      if (iframe) {
        iframe.load(publicJobId);
      }
      setLoaded(true);
    }
  }, [isLoaded, publicJobId]);

  useEffect(() => {
    window.addEventListener("message", handleSubmission, false);

    return () => {
      return (window as WindowWithGrnhseGlobal).removeEventListener(
        "message",
        handleSubmission
      );
    };
  }, [publicJobId]);

  return (
    <div data-testid="greenhouse-job-application-form">
      <Script
        src="//boards.eu.greenhouse.io/embed/job_board/js?for=truelayer"
        onLoad={onLoadCallback}
        strategy={ScriptStrategy.idle}
      />

      {isLoaded ? <Box id={IFRAME_ID} /> : <Loader />}
    </div>
  );
}
