import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { VStack } from "~components/VStack";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export interface ContentCalloutProps extends StoryblokBlok {
  className?: string;
  text?: StoryblokFieldRichText;
  textAlign?: GetSprinklesArgs["textAlign"];
  textColor?: GetSprinklesArgs["color"];
  title?: string;
}

/**
 * Renders a card with a title and a short block of rich text.
 * Used in content like reports and blogs to highlight  important information.
 */
export function ContentCallout({
  title,
  text,
  className: userClassName,
  textColor,
  ...rest
}: ContentCalloutProps) {
  if (!text && !title) return null;

  return (
    <VStack
      borderRadius="sm"
      border="default"
      gap="spacing1.5"
      padding="spacing3"
      className={clsx(variantBackgroundColor.background_sand, userClassName)}
      {...rest}
    >
      {title && <Box textAppearance="prefix_lg">{title}</Box>}

      {text && <StoryblokRichText text={text} color={textColor} />}
    </VStack>
  );
}
