import React from "react";

import { faDownload } from "@fortawesome/sharp-regular-svg-icons/faDownload";

import { FormHubspot } from "~components/FormHubspot";

import { Dialog } from "~sections/Dialog";

import type { DispatchConversionEventArgs } from "~utils/analytics/dispatchConversionEvent";

export interface ContentSidebarHubspotFormProps {
  ctaText?: string;
  hubspotFormId?: string;
  analyticsData: DispatchConversionEventArgs;
}

export function ContentSidebarHubspotForm({
  ctaText,
  hubspotFormId,
  analyticsData,
}: ContentSidebarHubspotFormProps) {
  if (!hubspotFormId || !ctaText) return null;

  return (
    <Dialog
      dialogTitle={ctaText}
      buttonTitle={ctaText}
      buttonProps={{
        marginTop: "auto",
        appearance: "tertiary",
        iconLeft: faDownload,
      }}
    >
      <FormHubspot
        padding="spacing3"
        formId={hubspotFormId}
        analyticsData={analyticsData}
      />
    </Dialog>
  );
}
