import React from "react";

import { faLocationPin } from "@fortawesome/sharp-regular-svg-icons/faLocationPin";

import { Tag } from "~components/Tag";

export interface CardLocationProps {
  location: string;
}

export function CardLocation({ location }: CardLocationProps) {
  return <Tag iconLeft={faLocationPin} tagSize="sm" title={location} />;
}
