export const getWistiaWrapperId = (id: string): string => {
  return `wistia_wrapper_${id}`;
};

const getWistiaFontFamilyName = (fontFamily: string): string => {
  return fontFamily.replace(" ", "%20");
};

export const getWistiaFontAttribute = (
  fontFamily: string | undefined
): string => {
  return fontFamily ? `customFont=${getWistiaFontFamilyName(fontFamily)}` : "";
};
