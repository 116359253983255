import React from "react";

import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";

import { Box } from "~components/Box";
import { FormHubspotDialog } from "~components/FormHubspotDialog";
import { VStack } from "~components/VStack";

import type { BoxProps } from "~components/Box";
import type { CtaCardProps } from "~components/CtaCard";

export type CtaCardSmallProps = BoxProps &
  Pick<
    CtaCardProps,
    "title" | "text" | "buttonText" | "link" | "conversionType"
  >;

/** Renders a cta card with text and a button that navigates the user to a supplied link. */
export function CtaCardSmall({
  buttonText,
  title,
  text,
  conversionType,
  ...rest
}: CtaCardSmallProps) {
  return (
    <VStack
      width="100%"
      gap="spacing2"
      border="default"
      borderRadius="md"
      paddingX="spacing2"
      display={{ mobile: "none", desktop: "flex" }}
      {...rest}
    >
      {title && (
        <Box textAppearance="body_lg" fontWeight="bold" maxWidth="gridSpan6">
          {title}
        </Box>
      )}

      {text && (
        <Box textAppearance="body_md" maxWidth="gridSpan6">
          {text}
        </Box>
      )}

      {buttonText && (
        <FormHubspotDialog
          dialogTitle={buttonText}
          buttonTitle={buttonText}
          buttonProps={{
            appearance: "primary",
            iconRight: faArrowRightLong,
          }}
          conversionType={conversionType}
          conversionComponent="CtaCardSmall"
        />
      )}
    </VStack>
  );
}
