import React from "react";

import { StoryblokRichText } from "~components/StoryblokRichText";

import * as styles from "../styles.css";

import type { StoryblokRichTextProps } from "~components/StoryblokRichText";

export interface CardDescriptionProps extends StoryblokRichTextProps {}

export function CardDescription({ text }: CardDescriptionProps) {
  return <StoryblokRichText className={styles.description} text={text} />;
}
