import React from "react";

import * as styles from "./styles.css";
import { getWistiaFontAttribute, getWistiaWrapperId } from "./wistiaUtils";

export interface ChannelWistiaProps {
  channelId: string;
  customFont?: string;
}

export function ChannelWistia({ channelId, customFont }: ChannelWistiaProps) {
  const wrapperId = getWistiaWrapperId(channelId);
  const fontAttribute = getWistiaFontAttribute(customFont);

  React.useEffect(() => {
    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/channel/${channelId}`;
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/channel.js";
    script2.async = true;
    const wrapper = document.getElementById(wrapperId);
    wrapper?.appendChild(script1);
    wrapper?.appendChild(script2);
  }, [channelId, wrapperId]);

  return (
    <div className={styles.customChannelWrapper} id={wrapperId}>
      <div
        className={`wistia_channel wistia_async_${channelId} ${fontAttribute}`}
      />
    </div>
  );
}
