import React from "react";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import * as styles from "../styles.css";

import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export const getGridColors = (
  backgroundColor?: VariantBackgroundColorEnum | null
) => {
  let stroke: string = colorTokens_latest.Primary.Pure_white["100"].value;
  let fill = stroke;

  if (
    backgroundColor === "background_white" ||
    backgroundColor === "background_sand"
  ) {
    stroke = colorTokens_latest.Primary.Sand_grey["110"].value;
    fill = colorTokens_latest.Primary.Lavender["100"].value;
  }

  return { stroke, fill };
};

export function SvgCardGridBackground({
  backgroundColor,
}: {
  backgroundColor: VariantBackgroundColorEnum;
}) {
  const { stroke, fill } = getGridColors(backgroundColor);

  return (
    <svg
      viewBox="0 0 313 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.gridStyle}
    >
      <path
        d="M44 77L44 85C44 93.8366 36.8366 101 28 101L0 101M44 77C61.6731 77 76 62.6731 76 45C76 27.3269 61.6731 13 44 13M44 77C26.3269 77 12 62.6731 12 45C12 27.3269 26.3269 13 44 13M44 13L44 1.9233e-06"
        stroke={stroke}
      />
      <line x1="226.5" y1="43" x2="226.5" y2="2.18557e-08" stroke={stroke} />
      <path
        d="M237 91.5H203M237 91.5C237 98.9558 243.044 105 250.5 105C257.956 105 264 98.9558 264 91.5M237 91.5C237 84.0442 243.044 78 250.5 78C257.956 78 264 84.0442 264 91.5M313 91.5H264"
        stroke={stroke}
      />
      <path d="M76 43.5009L267 43.501L308.501 2.00002" stroke={stroke} />
      <line y1="43.5" x2="12" y2="43.5" stroke={stroke} />
      <path
        d="M1 101H77.8325H153C166.255 101 177 90.2548 177 77V1"
        stroke={stroke}
      />
      <path
        d="M1 151H98.3503H200C213.255 151 224 140.255 224 127V115"
        stroke={stroke}
      />
      <path
        d="M313 115H264.98H227C213.745 115 203 104.255 203 91V44"
        stroke={stroke}
      />
      <path
        d="M149 101L139.919 101V101C125.604 101 114 89.3958 114 75.0812V44"
        stroke={stroke}
      />
      <path
        d="M52 126.5C52 112.417 63.6692 101 77.7525 101V101C91.5596 101 103 112.193 103 126V126C103 139.807 91.8071 151 78 151H52V126.5Z"
        stroke={stroke}
      />
      <path
        d="M52 151.5L52 151L51.5 151L25.5 151C11.969 151 1 161.969 0.999999 175.5L0.999998 202.5L0.999998 203L1.5 203L27.5 203C41.031 203 52 192.031 52 178.5L52 151.5Z"
        stroke={stroke}
      />
      <circle cx="76" cy="151" r="2" fill={fill} />
      <circle cx="277" cy="115" r="2" fill={fill} />
      <circle cx="226.5" cy="23" r="2" fill={fill} />
      <rect x="135" y="41" width="20" height="5" rx="2.5" fill={fill} />
    </svg>
  );
}
