import React from "react";

import { Box } from "~components/Box";

import * as styles from "../styles.css";

export interface CardTitleProps {
  title?: string;
}

export function CardTitle({ title }: CardTitleProps) {
  return (
    <Box as="h3" className={styles.title}>
      {title}
    </Box>
  );
}
