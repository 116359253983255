import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { FormHubspotDialog } from "~components/FormHubspotDialog";

import type { BoxProps } from "~components/Box";
import type { ConversionEvent } from "~config/CONVERSION_TYPES.config";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export interface CtaCardHubspotFormDialogProps
  extends Omit<BoxProps, "background"> {
  buttonText?: string;
  formId?: string;
  text?: string;
  title?: string;
  background?: VariantBackgroundColorEnum;
  conversionType: ConversionEvent;
}

/** Renders a cta card with text and a button that navigates the user to a supplied link. */
export function CtaCardHubspotFormDialog({
  buttonText,
  formId,
  text,
  background = "background_charcoal",
  title,
  conversionType,
  ...rest
}: CtaCardHubspotFormDialogProps) {
  return (
    <Box
      borderRadius="sm"
      border="default"
      width="100%"
      paddingTop="spacing3"
      paddingBottom="spacing4"
      paddingX="spacing3"
      id="cta"
      color="text_highContrast"
      className={variantBackgroundColor[background]}
      {...rest}
    >
      <Box textAppearance="h6" maxWidth="gridSpan8" marginBottom="spacing1">
        {title}
      </Box>
      <Box
        textAppearance="body_md"
        maxWidth="gridSpan8"
        marginBottom="spacing4"
      >
        {text}
      </Box>

      {title && (
        <FormHubspotDialog
          hubspotFormId={formId}
          dialogTitle={title}
          buttonTitle={buttonText}
          conversionType={conversionType}
          conversionComponent="CtaCardHubspotFormDialog"
        />
      )}
    </Box>
  );
}
