import React from "react";

import { FormHubspot } from "~components/FormHubspot";

import { Dialog } from "~sections/Dialog";

import type { ButtonProps } from "~components/Button";
import type { ConversionEvent } from "~config/CONVERSION_TYPES.config";
import type { DialogProps } from "~sections/Dialog";
import type { ConversionComponent } from "~utils/analytics/dispatchConversionEvent";

export interface FormHubspotDialogProps extends Omit<DialogProps, "children"> {
  hubspotFormId?: string;
  dialogTitle: string;
  buttonTitle?: string;
  buttonProps?: ButtonProps;
  conversionType: ConversionEvent;
  conversionComponent: ConversionComponent;
}

export function FormHubspotDialog({
  hubspotFormId,
  dialogTitle,
  buttonTitle,
  buttonProps,
  conversionType,
  conversionComponent,
  ...rest
}: FormHubspotDialogProps) {
  return (
    <Dialog
      dialogTitle={dialogTitle}
      buttonTitle={buttonTitle}
      buttonProps={buttonProps}
      isOverNavbar
      {...rest}
    >
      <FormHubspot
        padding="gutterWidth"
        formId={hubspotFormId}
        analyticsData={{
          type: conversionType,
          cta: buttonTitle,
          component: conversionComponent,
        }}
      />
    </Dialog>
  );
}
