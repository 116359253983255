import React from "react";

import { SvgLogo } from "~components/SvgLogo";

import * as styles from "../styles.css";

import type { SvgLogoProps } from "~components/SvgLogo";

export interface CardLogosListProps {
  logos?: Array<SvgLogoProps["svgLogo"]> | null;
}

export function CardLogosList({ logos }: CardLogosListProps) {
  return (
    <div className={styles.logosList}>
      {logos?.map((logoListItem) => {
        return (
          <SvgLogo
            className={styles.logoListItem}
            color="text_highContrast"
            key={logoListItem}
            svgLogo={logoListItem}
          />
        );
      })}
    </div>
  );
}
