// Adapted from
// https://github.com/aaronhayes/react-use-hubspot-form/blob/master/src/index.ts
import React, { useContext, useEffect, useState } from "react";

import { Script, ScriptStrategy } from "gatsby";

import { invalidInputStyle } from "~styles/common/a11y.error.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { dispatchConversionEvent } from "~utils/analytics/dispatchConversionEvent";

import { Box } from "~components/Box";
import { Loader } from "~components/Loader";

import { HUBSPOT_FORMS_MAP } from "~config/HUBSPOT.config";
import { PageQueryContext } from "~context/PageQueryContext";

import * as styles from "./styles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { WindowWithHbsptGlobal } from "~types/hubspot-forms.types";
import type { DispatchConversionEventArgs } from "~utils/analytics/dispatchConversionEvent";

export interface FormHubspotProps extends GetSprinklesArgs {
  formId?: string;
  layout?: "vertical" | "horizontal";
  portalId?: string;
  analyticsData: DispatchConversionEventArgs | undefined;
  conversionType?: DispatchConversionEventArgs["type"];
  hasPadding?: boolean;
  hasLeftLabels?: boolean;
}
const PREFIX = "__form";
let id = 0;

function getClassName(): string {
  id += 1;

  return [PREFIX, id].join("-");
}

function removeForm(target: string) {
  const form = document.querySelector(`${target} form`);
  if (form) {
    form.parentNode?.removeChild(form);
  }
}

export function FormHubspot({
  portalId = "3954168" /** <- this is not sensitive */,
  layout,
  formId: userFormId,
  analyticsData: parentAnalyticsData,
  conversionType = "contact_sales",
  hasPadding = false,
  hasLeftLabels = false,

  ...rest
}: FormHubspotProps) {
  const { pageContext } = useContext(PageQueryContext);
  const { lang } = pageContext || {};

  const formId = HUBSPOT_FORMS_MAP[lang || "en-gb"];

  const [isLoaded, setIsLoaded] = useState(false);
  const [formClassName] = useState<string>(getClassName());
  const styleWithPadding: string = hasPadding
    ? styles.desktopStyleWithPadding
    : "";
  const labelsStyle = hasLeftLabels ? "leftLabels" : "";

  const classNames = `${formClassName?.toString()} ${styleWithPadding} ${labelsStyle}`;

  const defaultAnalyticsData: DispatchConversionEventArgs = {
    type: conversionType,
    component: "FormHubspot",
    cta: "contact us",
  };
  const analyticsData = parentAnalyticsData ?? defaultAnalyticsData;

  useEffect(() => {
    const target = `.${formClassName}`;

    if (isLoaded && (window as WindowWithHbsptGlobal).hbspt) {
      (window as WindowWithHbsptGlobal).hbspt.forms.create({
        css: "",
        cssClass: styles.getFormStyles({
          layout,
        }),
        errorClass: invalidInputStyle,
        errorMessageClass: getSprinkles({
          fontWeight: "extrabold",
        }),
        formId: userFormId || formId,
        formInstanceId: formClassName,
        onFormSubmitted: () => {
          dispatchConversionEvent(analyticsData);
        },
        portalId,
        submitButtonClass: styles.submitButton,
        target,
      });
    }

    /** Remove form (if exists) when hook re-runs */
    return () => {
      removeForm(target);
    };
  }, [
    analyticsData,
    formClassName,
    formId,
    isLoaded,
    lang,
    layout,
    portalId,
    userFormId,
  ]);

  return (
    <>
      <Script
        strategy={ScriptStrategy.idle}
        src="//js.hsforms.net/forms/v2.js"
        onLoad={() => {
          setIsLoaded(true);
        }}
      />

      {isLoaded ? (
        <Box id={id.toString()} className={classNames} {...rest} />
      ) : (
        <Loader />
      )}
    </>
  );
}
