import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { animate, inView, spring } from "motion";

import { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";

import { Box } from "~components/Box";

import { MOTION_SPRING_LONG } from "~config/SPRING_CONFIG";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";

export interface ClipPathMaskWrapperProps extends BoxProps {
  mask?: keyof typeof varsClipPathInset;
  children: ReactNode | Array<ReactNode>;
}

export function ClipPathMaskWrapper({
  children,
  mask = "hidden",
  className: userClassName,
  ...rest
}: ClipPathMaskWrapperProps) {
  const refOuterElement = useRef<HTMLDivElement>(null);

  const [initialMask, setInitialMask] =
    useState<keyof typeof varsClipPathInset>("hidden");

  useEffect(() => {
    setInitialMask(mask || "rectangular");
  }, [mask]);

  useEffect(() => {
    if (!refOuterElement.current || typeof window === "undefined") return;

    inView(refOuterElement.current, ({ target }) => {
      animate(
        target,
        {
          clipPath: [varsClipPathInset[initialMask], varsClipPathInset[mask]],
        },
        {
          easing: spring(MOTION_SPRING_LONG),
        }
      );
    });
  }, [initialMask, mask]);

  return (
    <Box
      ref={refOuterElement}
      clipPath={mask}
      className={clsx(userClassName, varsClipPathInset[mask])}
      {...rest}
    >
      {children}
    </Box>
  );
}
