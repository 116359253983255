import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { FormHubspot } from "~components/FormHubspot";
import { VStack } from "~components/VStack";

import { SectionHeader } from "../SectionHeader";

import type { SectionHeaderProps } from "../SectionHeader";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { VariantThemeEnum } from "~styles/themes/variantThemeColorPalette.css";
import type { StoryblokBlokArray } from "~types/storyblok.types";

export interface CtaNewsletterProps {
  formId: string;
  portalId: string;
  title: string;
  subtitle: string;
  sectionHeader: Array<StoryblokBlokArray & SectionHeaderProps>;
  theme?: VariantThemeEnum;
  background?: VariantBackgroundColorEnum;
}

/** Renders a cta card with a title, subtitle and newsletter sign up form. */
export function CtaNewsletter({
  sectionHeader: sectionHeaderBlokArray,
  formId,
  title,
  subtitle,
  background = "background_charcoal",
  ...rest
}: CtaNewsletterProps) {
  const [sectionHeader] = sectionHeaderBlokArray || [];

  return (
    <Box
      borderRadius="sm"
      border="default"
      width="100%"
      paddingY="spacing4"
      paddingX="spacing4"
      id="cta"
      color="text_highContrast"
      className={variantBackgroundColor[background]}
      {...rest}
    >
      <Box textAppearance="h5" paddingBottom="spacing2">
        {title}
      </Box>
      <Box textAppearance="body_md">{subtitle}</Box>
      <VStack width="100%" paddingTop="spacing4" maxWidth="100%">
        {sectionHeader && <SectionHeader {...sectionHeader} />}
        {formId && (
          <FormHubspot
            layout="horizontal"
            formId={formId}
            maxWidth="100%"
            analyticsData={{
              type: "newsletter_signup",
              component: "CtaNewsletter",
              cta: title,
            }}
          />
        )}
      </VStack>
    </Box>
  );
}
