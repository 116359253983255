import React from "react";

import { Box } from "~components/Box";
import { IconBrand } from "~components/IconBrand";

import * as styles from "../styles.css";

import type { IconBrandProps } from "~components/IconBrand";

export interface CardIconProps {
  brandIcon?: IconBrandProps["icon"] | null;
}

export function CardIcon({ brandIcon }: CardIconProps) {
  return (
    <Box className={styles.logoOuter}>
      <IconBrand icon={brandIcon} className={styles.brandIcon} />
    </Box>
  );
}
