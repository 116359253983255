import React from "react";

import { Box } from "~components/Box";
import { Image } from "~components/Image";

import type { BoxProps } from "~components/Box";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface CardImageProps extends BoxProps {
  boxClassName?: string;
  imageWrapperClassName?: string;
  imageClassName?: string;
  image?: StoryblokFieldMedia | null;
}

export function CardImage({
  boxClassName,
  imageWrapperClassName,
  imageClassName,
  image,
  height,
  width,
  objectFit,
  position,
  children,
}: CardImageProps) {
  return (
    <Box className={boxClassName} position={position}>
      {image && (
        <Image
          height={height}
          width={width}
          image={image}
          className={imageWrapperClassName}
          imgClassName={imageClassName}
          objectFit={objectFit}
        />
      )}
      {children}
    </Box>
  );
}
